<template>
  <div style="display: inline-flex;">
    <b-btn
      v-b-tooltip.hover
      title="Visualizar Última Declaração PGDAS-D 2018"
      variant="none"
      class="btn-actions"
      style="padding: 5px;"
      @click="abrirArquivo(gridProps.row)"
    >
      <font-awesome-icon
        icon="fa-sharp fa-regular fa-clipboard"
        class="text-primary"
        style="font-size:1.3rem !important;width:22px"
      />
    </b-btn>

    <visualizar-arquivo
      v-if="mostarArquivo && arquivo.url"
      :url="arquivo.url"
      @hidden="fecharArquivo()"
    />

    <envio-email
      ref="modalEnvioEmail"
      :title="`Enviar Sublimite ${gridProps.row.razaoSocial}`"
      @enviarEmail="enviarSublimite"
    />
  </div>
</template>

<style lang="scss">
.acoes-usuario {
  margin-right: 0.3rem;
  padding: 0.4rem;
}
</style>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '../../services/api'

export default {
  components: {
    FontAwesomeIcon,
    VisualizarArquivo: () => import('@pilar/components/visualizar-arquivo/VisualizarArquivo.vue'),
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
  },
  props: {
    gridProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      faExternalLinkAlt,
      mostarArquivo: false,
      arquivo: {},
      docSelecionado: null,
      enviarEmpresa: false,
      emailCc: '',
      empresaEmail: '',
    }
  },
  methods: {
    abrirArquivo(documento) {
      this.docSelecionado = documento
      api.getURLSublimite(documento.id)
        .then(payload => {
          this.arquivo.url = payload.data
          this.mostarArquivo = true
        })
        .catch(err => {
          this.fecharArquivo()
          this.notificarErro('Não foi possível abrir a visualização de arquivo.', err)
          throw err
        })
    },
    fecharArquivo() {
      this.mostarArquivo = false
      this.docSelecionado = null
      this.arquivo = {}
    },

    notificarErro(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
    },

    openModalEnviarSublimite() {
      this.$refs.modalEnvioEmail.$refs.modalEnviarEmail.show()
    },

    enviarSublimite(itens) {
      if (itens.emailsPara.length === 0 && itens.emailsCc === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informe ao menos um e-mail',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      } else {
        api.notificaSublimite(this.gridProps.row.razaoSocial, itens.emailsPara, itens.emailsCc, this.gridProps.row.id)
        this.emailCc = ''
      }
    },

    validator(tag) {
      const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(tag)
    },

    alterarEmailEmpresa() {
      if (this.enviarEmpresa) {
        this.empresaEmail = this.gridProps.row.email
      } else {
        this.empresaEmail = ''
      }
    },
  },
}
</script>
